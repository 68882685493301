<template>
  <div>
    <Navbar page="Gestão de Treinamentos" video="https://www.youtube.com/watch?v=hfilJ-tiuS4" />
    <div class="mx-4 my-4 md:mx-8 md:my-8">
      <button v-if="!$store.state.user.cliente" @click="novoEvento()" type="button" 
        :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }"
        class="inline-block transition duration-200 bg-yellow-300 hover:bg-yellow-400 
        focus:bg-yellow-700 focus:shadow-sm focus:ring-4 
        focus:ring-yellow-500 focus:ring-opacity-50 text-white py-2 
        rounded-full text-sm shadow-sm hover:shadow-md w-full mr-2 md:w-52 font-semibold text-center
        mb-4
        "
      >
          <span class="inline-block mr-2">Novo evento</span>
      </button>

      <router-link v-if="!$store.state.user.cliente" to="/avaliacoes" type="button" 
        class="inline-block transition duration-200 bg-green-600 hover:bg-green-700 
        focus:bg-green-700 focus:shadow-sm focus:ring-4 
        focus:ring-green-700 focus:ring-opacity-50 text-white py-2 
        rounded-full text-sm shadow-sm hover:shadow-md w-full mr-2 md:w-56 font-semibold text-center
        mb-4
        "
      >
          <span class="inline-block mr-2">Avaliações / testes</span>
      </router-link>

      <router-link v-if="!$store.state.user.cliente" to="#" type="button" 
        class="cursor-not-allowed inline-block transition duration-200 bg-gray-300
       text-black py-2 
        rounded-full text-sm w-full mr-2 md:w-64 font-semibold text-center
        mb-4
        "
      >
          <span class="inline-block mr-2">Gestão de conteúdos (em breve)</span>
      </router-link>
      <router-link v-if="!$store.state.user.cliente" to="#" type="button" 
        class="cursor-not-allowed inline-block transition duration-200 bg-gray-300
       text-black py-2 
        rounded-full text-sm w-full mr-2 md:w-72 font-semibold text-center
        mb-4
        "
      >
          <span class="inline-block mr-2">Simulações de segurança (em breve)</span>
      </router-link>

      <button @click="excluirSelecionadas" v-if="!$store.state.user.cliente && selecionados.length" type="button" class="transition duration-200 bg-red-500 hover:bg-red-700 text-white py-2 rounded-lg text-sm shadow-sm hover:shadow-md w-full md:w-72 font-semibold text-center mb-2 md:ml-4">
        <span class="inline-block">Arquivar selecionadas</span>
      </button>

      <div class="grid grid-cols-12 gap-4 mb-3">
        <div class="col-span-12 md:col-span-3">
            <label for="nome" class="block text-sm font-medium">Filtrar por:</label>
            <input v-model="nome" type="text" name="nome" id="nome" placeholder="Nome do evento" class="mt-1 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
        </div>
        <div class="col-span-12 md:col-span-3">
            <label for="participante" class="block text-sm font-medium">&nbsp;</label>
            <input v-model="participante" type="text" name="participante" id="participante" placeholder="Nome de participante" class="mt-1 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
        </div>
        <div class="col-span-12 md:col-span-3">
            <label for="dataInicio" class="block text-sm font-medium">&nbsp;</label>
            <input v-model="dataInicio" type="date" name="dataInicio" class="mt-1 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"/>
        </div>
        
        <div class="col-span-12 md:col-span-3">
          <label class="block text-sm font-medium">&nbsp;</label>
          <button @click="updateFiltro" class="mt-1 bg-gray-500 hover:bg-gray-600 focus:bg-gray-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-3xl text-sm shadow-sm hover:shadow-md w-full font-semibold text-center">Buscar </button>
        </div>
      </div>

      <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                    <tr>
                      <th scope="col" class="px-3 py-3 text-left text-sm font-medium text-gray-500 tracking-wider cursor-pointer">
                        <svg @click="selecionarTodos" xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#000000" viewBox="0 0 256 256"><path d="M220,48V208a12,12,0,0,1-12,12H136a4,4,0,0,1,0-8h72a4,4,0,0,0,4-4V48a4,4,0,0,0-4-4H48a4,4,0,0,0-4,4v96a4,4,0,0,1-8,0V48A12,12,0,0,1,48,36H208A12,12,0,0,1,220,48ZM117.17,157.17,64,210.34,42.83,189.17a4,4,0,0,0-5.66,5.66l24,24a4,4,0,0,0,5.66,0l56-56a4,4,0,0,0-5.66-5.66Z"></path></svg>
                      </th>
                      <th scope="col" class="px-2 py-3 text-center text-sm font-medium text-gray-500 tracking-wider">
                        Tipo
                      </th>
                      <th scope="col" class="px-2 py-3 text-center text-sm font-medium text-gray-500 tracking-wider">
                        Nome do evento
                      </th>
                      <th scope="col" class="px-2 py-3 text-center text-sm font-medium text-gray-500 tracking-wider">
                        Data de início
                      </th>
                      <th scope="col" class="px-2 py-3 text-center text-sm font-medium text-gray-500 tracking-wider">
                        Status
                      </th>
                      <th scope="col" class="px-2 py-3 text-center text-sm font-medium text-gray-500 tracking-wider">
                        Indice de participação
                      </th>
                      <th scope="col" class="px-2 py-3 text-center text-sm font-medium text-gray-500 tracking-wider">
                        Média das avaliações 
                      </th>
                      <th scope="col" class="px-2 py-3 text-center text-sm font-medium text-gray-500 tracking-wider w-32 md:w-64 lg:w-128">
                        Opções
                      </th>
                      <th scope="col" class="px-2 py-3 text-center text-sm font-medium text-gray-500 tracking-wider w-32">
                        Arquivar
                      </th>
                    </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                    <tr v-for="item in list" :key="item._id">
                      <td class="px-3 py-3 whitespace-nowrap">
                        <div class="flex items-center">
                          <input :checked="selecionados.map(el => el._id).indexOf(item._id) > -1" @change="(e) => selecionadosControl(item, e)" type="checkbox" class="rounded-sm">
                        </div>
                      </td>
                      <td class="px-2 py-3">
                          <div class="text-sm text-gray-500 text-center">
                            {{item.tipo ? item.tipo : ''}}
                          </div>
                      </td>
                      <td class="px-2 py-3">
                          <div class="text-sm text-gray-500 text-center">
                            {{item.nome ? item.nome : ''}}
                          </div>
                      </td>
                      <td class="px-2 py-3">
                        <div v-if="item.dataInicio" class="text-sm text-gray-500 text-center">
                          {{ item.dataInicio | moment("DD/MM/YYYY")}}
                        </div>
                      </td>
                      <td class="px-2 py-3">
                          <div class="text-sm text-gray-500 text-center" :class="item.status === 'Em andamento' ? 'text-yellow-500': 'text-green-500 font-semibold' ">
                            {{item.status ? item.status : ''}}
                          </div>
                      </td>
                      <td class="px-2 py-3">
                          <div v-if="item.presentes > 0 && item.convidados > 0" class="text-sm text-gray-500 text-center">
                            {{ parseFloat((item.presentes / item.convidados) * 100).toFixed(1) }} % 
                          </div> 
                          <div v-else class="text-sm text-gray-500 text-center">
                            0.0 % 
                          </div>
                      </td>
                      <td class="px-2 py-3">
                          <div v-if="item.avaliacao" class="text-sm text-center text-gray-500">
                            {{item.media ? parseFloat(item.media * 100).toFixed(0) :"0.0"}}
                          </div> 
                      </td>
                      <td class="px-2 py-3 text-sm font-medium">
                        <div class="grid grid-cols-12 gap-1">
                          <router-link type="button" :to="`/${route}/form/${item._id}`" 
                            class="text-black hover:bg-yellow-500 bg-yellow-400 rounded-full py-2 px-2 col-span-12 lg:col-span-12 2xl:col-span-6 text-center">
                              Editar evento
                          </router-link>
                          <router-link type="button" :to="`/${route}/convidados/${item._id}`" 
                            class="text-white hover:bg-gray-600 bg-gray-700 rounded-full py-2 px-2 col-span-12 lg:col-span-12 2xl:col-span-6 text-center">
                              Gerenciar participantes
                          </router-link>
                          <router-link type="button" :to="`/${route}/corrigir/${item._id}`" 
                            class="text-white hover:bg-grenn-700 bg-green-500 rounded-full py-2 px-2 col-span-12 lg:col-span-12 2xl:col-span-6 text-center">
                            Corrigir avaliações
                          </router-link>
                          <button
                            @click="duplicar(item._id)" 
                            type="button" 
                            class="text-white hover:bg-blue-500 bg-blue-700 rounded-full py-2 px-2 col-span-12 lg:col-span-12 2xl:col-span-6 text-center">
                            Duplicar evento
                          </button>
                        </div>
                      </td>
                      <td class="text-center">
                        <button
                            @click="remove(item._id)" 
                            type="button" 
                            class="text-white hover:bg-red-500 bg-red-700 rounded-full py-2 px-2  text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#f7f7f7" viewBox="0 0 256 256"><path d="M216,48H176V40a24,24,0,0,0-24-24H104A24,24,0,0,0,80,40v8H40a8,8,0,0,0,0,16h8V208a16,16,0,0,0,16,16H192a16,16,0,0,0,16-16V64h8a8,8,0,0,0,0-16ZM96,40a8,8,0,0,1,8-8h48a8,8,0,0,1,8,8v8H96Zm96,168H64V64H192ZM112,104v64a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Zm48,0v64a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Z"></path></svg>
                          </button>
                      </td>
                    </tr>
                </tbody>
              </table>
            </div>

            <label for="perPage">Itens por página
              <select class="w-20 mt-2 mr-1 py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm" @change="start" v-model="perPage">
                <option :value="5">5</option>
                <option :value="10">10</option>
                <option :value="20">20</option>
                <option :value="50">50</option>
                <option :value="100">100</option>
              </select>
            </label>

            <pagination v-if="total > 0" v-model="query.page" :per-page="perPage" :records="total" @paginate="setPage" class="pagination" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      route: 'treinamentos',
      list: [],
      selecionados: [],
      page: 1,
      perPage: 20,
      total: 0,
     
      nome: "",
      participante: "",
      dataInicio: null,
     
      query: { page: 1, limit: 10, skip: 0  },
    }
  },
  methods: {

    async start() {
      this.query.limit = this.perPage;

      const listReq = await this.$http.post(`/v1/${this.route}/list`, { skip: this.query.skip, limit: this.query.limit, nome: this.nome, participante: this.participante, dataInicio: this.dataInicio });
      
      this.list = listReq.data.data;
      this.total = listReq.data.total;
    },

    async excluirSelecionadas(){
      this.$confirm({
        title: 'Arquivar selecionados',
        message: `Deseja arquivar itens selecionados ?`,
        button: {
            no: 'Não',
            yes: 'Sim',
        },
        callback: async confirm => {
          if(!confirm) return;
          await this.$http.post(`/v1/${this.route}/selecionados/deletar`,{ selecionados: this.selecionados });
          this.selecionados = [];
          this.$vToastify.success("Arquivadas com sucesso!");
          this.start();
          this.grafico();
        }
      })
    },
    
    async remove(id) {
      this.$confirm({
          title: 'Confirmação',
          message: `Tem certeza que deseja arquivar esse treinamento ?`,
          button: {
              no: 'Não',
              yes: 'Sim',
          },
          callback: async confirm => {
            if (confirm) {
              await this.$http.delete(`/v1/${this.route}/${id}`);
              this.$vToastify.success("Removido com sucesso!");
              this.page = 1;
              this.start();
            }
          }
      });
    },

    async duplicar(id) {
      this.$confirm({
          title: 'Confirmação',
          message: `Tem certeza que deseja duplicar este treinamento ?`,
          button: {
              no: 'Não',
              yes: 'Sim',
          },
          callback: async confirm => {
            if (confirm) {
              const req = await this.$http.post(`/v1/${this.route}/duplicar`,{ treinamento: id });
              if(!req.data.success) return this.$vToastify.error(req.data.err);
              this.$vToastify.success("Duplicado com sucesso!");
              this.page = 1;
              this.start();
            }
          }
      });
    },

    async novoEvento(){
      const req = await this.$http.post(`/v1/${this.route}/`, { });
      if (req.data.success) {
          this.$router.push({ path: `/${this.route}/form/${req.data.data._id}` });
      } else {
          this.$vToastify.error(req.data.err);
      }
    },

    updateFiltro(){
      this.query.page = 1;
      this.query.skip = 0;
      this.query.limit = this.perPage;

      this.query = { page: this.query.page, limit: this.query.limit, skip: this.query.skip, nome: this.nome, participante: this.participante, dataInicio: this.dataInicio };
      this.$router.push({ path: `/${this.route}`, query: this.query });
    },
    async selecionarTodos(){
      if (!this.selecionados.length) {
        const listReq = await this.$http.post(`/v1/${this.route}/list`, { all: true, nome: this.nome, participante: this.participante, dataInicio: this.dataInicio });
        this.selecionados = listReq.data.data;
      } else {
        this.selecionados = [];
      }
    },

    selecionadosControl(item, e){
      if (this.selecionados.map(el => el._id).indexOf(item._id) > -1 && !e.target.checked) {
        this.selecionados.splice(this.selecionados.map(el => el._id).indexOf(item._id), 1);
      } else {
        this.selecionados.push(item);
      }
    },

    async setPage(page) {
      const skip = (page - 1) * this.perPage;
      const limit = this.perPage;

      this.query.page = page;
      this.query.skip = skip;
      this.query.limit = limit;

      this.$router.push({ path: `/${this.route}`, query: this.query });
    }
  },
  async beforeRouteUpdate(to, from, next) {
    next();

    if(this.$route.query.page) this.query.page = parseInt(this.$route.query.page);
    if(this.$route.query.limit) this.query.limit = parseInt(this.$route.query.limit);
    if(this.$route.query.skip) this.query.skip = parseInt(this.$route.query.skip);

    if(this.$route.query.nome) this.query.nome = this.$route.query.nome;
    if(this.$route.query.participante) this.query.participante = this.$route.query.participante;
    if(this.$route.query.dataInicio) this.query.dataInicio = this.$route.query.dataInicio;

    this.start();
  },
  async beforeMount() {
    if(this.$route.query.page) this.query.page = parseInt(this.$route.query.page);
    if(this.$route.query.limit) this.query.limit = parseInt(this.$route.query.limit);
    if(this.$route.query.skip) this.query.skip = parseInt(this.$route.query.skip);

    if(this.$route.query.nome) this.query.nome = this.$route.query.nome;
    if(this.$route.query.participante) this.query.participante = this.$route.query.participante;
    if(this.$route.query.dataInicio) this.query.dataInicio = this.$route.query.dataInicio;

    this.start();

    const listPessoas = await this.$http.post(`/v1/pessoas/list`, { all: true});
    this.pessoas = listPessoas.data.data;

    const listFornecedores = await this.$http.post(`/v1/fornecedores/list`, { all: true});
    this.fornecedores = listFornecedores.data.data; 

    if(this.$store.state.user.cliente) {
      this.$router.push({path:'/treinamentos-list'});
    }
  },
}
</script>